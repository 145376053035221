export const cookiePolicy = [
    {
        title: "Introduction",
        body: `This Cookie Policy explains how Keel Digital Solutions (“we,” “us,” or “our”) uses cookies and similar technologies on our website  https://keel.digital/) (the “Site”). By using the Site, you consent to the use of cookies as described in this policy.`,
    },
    {
        title: " 1. What are cookies?",
        body: `Cookies are small pieces of data stored on your device (computer or mobile device) when you visit a website. They are widely used to make websites work more efficiently and to provide information to the website owners.`,
    },
    {
        title: " 2. How we use cookies",
        body: `We use cookies for various purposes, including:\n
Essential Cookies: These cookies are necessary for the proper functioning of the website. They enable core functionality such as security, network management, and accessibility.\n
Analytics Cookies: These cookies help us understand how visitors interact with our website, providing information about areas visited, time spent on the site, and any issues encountered. This helps us improve the performance and user experience of our website.\n
Marketing Cookies: We may use these cookies to deliver personalized advertisements and promotional content based on your interests and online activities.`,
    },
    {
        title: " 3. Types of cookies we use",
        body: `Session Cookies: These are temporary cookies that are erased when you close your browser.
Persistent Cookies: These cookies remain on your device for a longer period, even after you close your browser. They are activated each time you visit the site.`,
    },
    {
        title: " 4. Your cookie choices",
        body: `You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. However, this may prevent you from taking full advantage of the website.`,
    },
    {
        title: " 5. Third-party cookies",
        body: `We use third-party services that use cookies. These third-party services have their own privacy policies, and we recommend reviewing them.`,
    },
    {
        title: " 6. Changes to this cookie policy",
        body: `We may update this Cookie Policy from time to time to reflect changes in technology, legislation, or our practices. Please review this policy regularly to stay informed about how we use cookies`,
    },
    {
        title: " 7. Contact",
        body: `If you have any questions about this Privacy Policy, please contact us at <a href="mailto:admin@keel.digital">admin@keel.digital</a>`,
    },
];

export const privacyPolicy = [
    {
        title: "A legal disclaimer",
        body: `Last updated: December 20, 2023
Keel Digital Solutions (“us”, “we”, or “our”) operates [www.keel.digital] (the “Site”). This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the Site. We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.`,
    },
    {
        title: "Information collection and use",
        body: `While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include but is not limited to your name (“Personal Information”).`,
    },
    {
        title: "Log data",
        body: `Like many site operators, we collect information that your browser sends whenever you visit our Site (“Log Data”). This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages, and other statistics.`,
    },
    {
        title: "Cookies",
        body: `Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer’s hard drive.
Like many sites, we use “cookies” to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.`,
    },
    {
        title: "Security",
        body: `The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.`,
    },
    {
        title: "Changes to this privacy policy",
        body: `This Privacy Policy is effective as of December 20, 2023 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page. We reserve the right to update or change our Privacy Policy at any time, and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.`,
    },
    {
        title: "Contact",
        body: `If you have any questions about this Privacy Policy, please ontact us at <a href="mailto:admin@keel.digital">admin@keel.digital</a>`,
    },
];
