import styles from "./SolutionComponent.module.css";
import { Link } from "react-router-dom";

function SolutionComponent({
    iconSrc,
    smallGraphicSrc,
    bigGraphicSrc,
    graphicAlt,
    heading,
    children,
    buttonHref,
    reversed = false,
}) {
    return (
        <div
            className={[
                styles.solution_component,
                reversed ? styles.reversed : undefined,
            ].join(" ")}
        >
            <div className={styles.left}>
                <img src={iconSrc} alt={heading + " icon"} />
                <h3 className={styles.heading}>{heading}</h3>
                <p className={styles.description}>{children}</p>
                <Link className="orange_button" to={buttonHref}>
                    Learn More
                    <img
                        src="/images/white-arrow-right.svg"
                        alt="arrow pointing right"
                    />
                </Link>
            </div>
            <div className={styles.right}>
                <picture>
                    <source
                        srcSet={smallGraphicSrc}
                        media="(max-width: 1370px)"
                    />
                    <img
                        className={styles.graphic_img}
                        src={bigGraphicSrc}
                        alt={graphicAlt}
                    />
                </picture>
            </div>
        </div>
    );
}

export default SolutionComponent;
