import styles from "./VideoHero.module.css";

function VideoHero({
    videoSrc = "/videos/hero-background-video.mp4",
    imgSrc = "/images/home-hero-wallpaper.webp",
    color = "var(--keel-navy)",
    id,
    children,
}) {
    return (
        <section id={id} className={styles.hero_container}>
            <div
                className={styles.hero_background_image}
                style={{
                    backgroundImage: `url(${imgSrc})`,
                    backgroundColor: color,
                }}
            ></div>
            <video
                loop={true}
                muted={true}
                autoPlay={true}
                playsInline={true}
                className={styles.hero_background_video}
                src={videoSrc}
            ></video>
            {children}
        </section>
    );
}

export default VideoHero;
