import styles from "./Policy.module.css";

function Policy({ title, data }) {
    return (
        <div id={styles.main}>
            <h1 id={styles.title}>{title}</h1>
            <div id={styles.policy_container}>
                {data.map((d) => (
                    <div key={d.title} className={styles.policy_item}>
                        <h3>{d.title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: d.body }}></p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Policy;
