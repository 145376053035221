export const ourLeadership = [
    {
        name: "Rob Godfrey",
        title: "President & CEO",
        headshot: "/images/rob-godfrey-headshot.jpg",
        linkedinUrl: "https://www.linkedin.com/in/rob-godfrey-87bba02/",
        shortDescription:
            "Rob is a serial entrepreneur with a background in business development, strategic marketing, and government relations.",
        fullDescription: `Rob is a serial entrepreneur with a background in business development, strategic marketing, and government relations. Over the past 15+ years, he has successfully launched start-ups across sectors like chemical manufacturing (Qwatro USA), waste & soil remediation (Clean Soil), and pet services (Park9). Formerly SVP of the Toronto Blue Jays, Chair of the Ontario SPCA, and investment banker at TD Securities, Rob sits on various private and public boards and holds a JD/MBA from Pepperdine University.`,
    },
    {
        name: "Jay Fischbach",
        title: "Chief Operating Officer",
        headshot: "/images/jay-fischbach-headshot.jpg",
        linkedinUrl: "https://www.linkedin.com/in/jay-fischbach-15ba044/",
        shortDescription:
            "Jay has spent his career in capital markets and technology management working both nationally and internationally.",
        fullDescription: `Jay has spent his career in capital markets and technology management working both nationally and internationally. Jay brings a wealth of knowledge from his roles at RBC, Jameson Bank, AFEX and most recently as the COO of Exchange Bank of Canada. He currently advises on technology and innovation strategies to both start-ups and public companies. H.B.Sc. and an MBA in Technology Management.`,
    },
    {
        name: "Ahad Bandealy",
        title: "Chief Digital Officer",
        headshot: "/images/ahad-bandealy-headshot.jpg",
        linkedinUrl: "https://www.linkedin.com/in/ahad-bandealy-a1394b139/",
        shortDescription:
            "Ahad has spent his career in behavioral health and psychology, working both nationally and internationally.",
        fullDescription: `Ahad has spent his career in behavioral health and psychology, working both nationally and internationally. Ahad brings a wealth of knowledge from his roles as a Ph.D. student, researcher, and practitioner-in-training at the University of Toronto and recently as the Founder & CEO of Get A-Head Inc. B.Sc. in psychology, M.Sc. in Exercise Science & Behavioral Health, Certification in Artificial Intelligence from MIT, MBA in Leadership and Healthcare Technologies.`,
    },
    {
        name: "Daniel Libertucci",
        title: "Chief Scientific Officer",
        headshot: "/images/daniel-libertucci-headshot.jpg",
        linkedinUrl: "https://www.linkedin.com/in/daniel-libertucci-b977768/",
        shortDescription:
            "Daniel has dedicated his career to the field of clinical diagnostics.",
        fullDescription: `Peter is a passionate entrepreneur and business leader. Over 25 years he has launched multiple successful innovation companies and is a trusted CEO advisor to a wide array of global companies, including IBM, Deloitte, Google, SAP, VMware, and Wipro, as well as to many political leaders.
Peter also has extensive Board experience across a wide range of private and public organizations, contributing his governance expertise, objective strategic insights and mentorship to top executive teams across technology, health care, sports governing bodies, government tribunals, and global retailers.`,
    },
    {
        name: "Kelly Ehler",
        title: "Chief Financial Officer",
        linkedinUrl: "https://www.linkedin.com/in/kellyehler/",
        headshot: "/images/kelly-ehler-headshot.webp",
        shortDescription:
            "Kelly Ehler brings over 30 years of experience in global finance, including as a CFO, and Board Member.",
        fullDescription: `Kelly Ehler brings over 30 years of experience in global finance, including as a CFO, and Board Member. With an extensive background in both public and private companies he brings a wealth and depth of knowledge in IPOs, RTOs, CPCs, Compliance, Debt Financing, Equity Structuring and M&A. His experience ranges from sectors as diverse as healthcare, mining and cannabis, and across jurisdictions in Canada, the US, Asia and Africa. He is a licensed CA, TEP (Trust Estate Practitioner) and LLQP.`,
    },
];

export const ourAdvisors = [
    {
        name: "Peter AP Zakarow",
        role: "Board member",
        title: "Vice Chair & Audit Chair, Board Member",
        linkedinUrl:
            "https://www.linkedin.com/in/peterzakarow/?originalSubdomain=ca",
        headshot: "/images/peter-zakarow-headshot.webp",
        shortDescription:
            "Peter is a passionate entrepreneur and business leader.",
        fullDescription: `Peter is a passionate entrepreneur and business leader. Over 25 years he has launched multiple successful innovation companies and is a trusted CEO advisor to a wide array of global companies, including IBM, Deloitte, Google, SAP, VMware, and Wipro, as well as to many political leaders.
    Peter also has extensive Board experience across a wide range of private and public organizations, contributing his governance expertise, objective strategic insights and mentorship to top executive teams across technology, health care, sports governing bodies, government tribunals, and global retailers.
    `,
    },
    {
        name: "Henry Dreifus",
        role: "Board member",
        title: "Entrepreneur, investor, director, innovator",
        description: "",
        linkedinUrl: "https://www.linkedin.com/in/henrydreifus/",
    },
    {
        name: "Pete Montour",
        role: "Board member",
        title: "Grand River Enterprises",
        description: "",
    },
    {
        name: "Sandy Climan",
        role: "Advisor",
        title: "CEO, Entertainment Media Ventures, Inc",
        description: `As a graduate of Harvard (MBA and MS Health Policy) Sandy has managed to succeed in both the Private sector and in Hollywood.  He was the EVP of Universal Studios, CEO of 3ality Digital and President of Entertainment Media Ventures.  Mr. Climan has held senior management positions in the media and entertainment industry throughout his career and is considered a "media visionary”.  In addition, he has been an active investor in, and advisor to, venture capital firms and early-stage companies in media, technology, and healthcare.`,
        linkedinUrl: "https://www.linkedin.com/in/sandycliman/",
    },
    {
        name: "Janet Kerr",
        role: "Advisor",
        title: "Vice Chancellor at Pepperdine University",
        description: `Janet is Vice-Chancellor of Pepperdine University and Professor Emeritus of Law at Pepperdine University School of Law where she taught for 30 years. At Pepperdine, Janet founded and was the first Executive Director of the Palmer Center for Entrepreneurship & the Law, was the first professor to hold the Laure Sudreau-Rippe Endowed Chair and served as Associate Dean of the Law School. Janet has additionally had extensive experience with public boards in the areas of retail, technology, food industry and the financial sector; currently sitting on three public boards.`,
        linkedinUrl: "https://www.linkedin.com/in/janet-kerr-22221b3/",
    },
    {
        name: "Dr. Georgia Witkin",
        role: "Advisor",
        title: "Head of Patient Services Development, Progyny",
        description: `After completing post-doctoral studies in behavioral genetics as a National Science Foundation Fellow at the New York State Psychiatric Institute, Columbia College of Physicians and Surgeons, Dr. Georgia Witkin joined the Department of Psychiatry at the Mount Sinai School of Medicine in New York City where she is an Assistant Professor of Psychiatry, Assistant Professor of Obstetrics, Gynecology and Reproductive Sciences, and Director of Psychological and Wellness Services for Reproductive Medicine Associates of New York.`,
        linkedinUrl: "https://www.linkedin.com/in/dr-georgia-witkin/",
    },
    // {
    //     name: "Dr. Murali Doraiswamy",
    // role: "Advisor",
    //     title: "Head of Patient Services Development, Progyny",
    //     description: `Murali Doraiswamy MBBS FRCP is Professor of Psychiatry and Professor in Medicine at Duke University School of Medicine where he is a highly cited physician scientist at the Duke Institute for Brain Sciences.  He is also a Senior Fellow at the Duke Center for the Study of Aging and an Affiliate Faculty at the Duke Center for Precision Medicine and Applied Genomics as well as the Duke Microbiome Center.  He directs a clinical trials unit that has been involved in the development of many modern diagnostic tests, apps, algorithms, and therapeutics in wide use today.`,
    //     linkedinUrl: "https://www.linkedin.com/in/murali-d-92a4541aa/",
    // },
    {
        name: "Eric Hargan",
        role: "Advisor",
        title: "Founder & CEO of The Hargan Group",
        description: `Eric Hargan served as the Deputy Secretary of the Department of Health and Human Services (HHS) from 2017 to 2021. With oversight of a $1.3 trillion annual budget and 80,000 employees across 26 divisions, he played a pivotal role in developing and approving regulations for HHS, CMS, and FDA. Notably, Eric served on the Board of Operation Warp Speed. He initiated the $175 billion Provider Relief Fund in response to the pandemic and facilitated the comprehensive HHS telehealth response.

Eric also led the Regulatory Sprint to Coordinated Care, reforming regulations for better-coordinated care, and played a key role in the launch of the interoperability rule. Additionally, he spearheaded the Innovation and Investment Summit, focusing on healthcare innovation, and led various initiatives in public health, including rural health reforms and participation in the WHO’s High-Level Commission on Non-Communicable Diseases.
`,
        linkedinUrl: "https://www.linkedin.com/in/eric-hargan-6a54ba7",
    },
    // {
    //     name: "Dr. Natan Bar-Chama",
    // role: "Advisor",
    //     title: "Director of the Center of Male Reproductive Health",
    //     description: `Dr. Natan Bar-Chama, Director of the Center of Male Reproductive Health at RMA of New York, is a board-certiﬁed Urologist and Male Infertility Specialist. Following his urology residency at the Albert Einstein College of Medicine, he was awarded the New York Academy of Medicine F.C. Valentine Fellowship and sub-specialized in Male Reproductive Medicine and Surgery at The Baylor College of Medicine in Houston, Texas.`,
    //     linkedinUrl: "https://www.linkedin.com/in/natan-bar-chama-74b7767/",
    // },
    // {
    //     name: "Lissa Kline",
    // role: "Advisor",
    //     title: "Senior Vice President, Provider and Member Services at Progyny",
    //     description: `Lissa is the SVP of Member Experience and Provider Relations at Progyny, Inc. She is a Licensed Clinical Social Worker and was the Patient Services Manager, and then ultimately the Operations Manager at Columbia University Medical Center. She oversees all training of Patient Care Advocates at Progyny, Inc and is responsible for selecting training and performance assessment platforms.`,
    //     linkedinUrl: "https://www.linkedin.com/in/lissa-kline-lcsw-43246b29/",
    // },
    // {
    //     name: "Carlos Hernandez Nieto",
    // role: "Advisor",
    //     title: "Reproductive Endocrinology and Infertility Specialist",
    //     description: `Carlos is the Associate Director of Clinical Research at Reproductive Medicine Associates of New York. He has published multiple scientific abstracts and articles in peer-reviewed journals and has received academic awards by the American Society for Reproductive Medicine .He is interested in developing a Spanish version of Get-ahead and then other languages as well.`,
    //     linkedinUrl: "https://www.linkedin.com/in/carloshernandezmd/",
    // },

    // {
    //     name: "Dr. Anate Brauer",
    // role: "Advisor",
    //     title: "IVF Director at Shady Grove Fertility, New York",
    //     description: `Dr. Brauer is a board-certified reproductive endocrinologist with extensive experience in treating all medical and surgical aspects of infertility. She serves as Shady Grove Fertility New York’s IVF Director. Prior to joining Shady Grove Fertility, Dr. Brauer was an Assistant Clinical Professor at NYU School of Medicine.`,
    //     linkedinUrl:
    //         "https://www.linkedin.com/in/anate-aelion-brauer-md-ms-facog-7120303b/",
    // },
    // {
    //     name: "Dara Godfrey",
    // role: "Advisor",
    //     title: "Registered Dietitian at Reproductive Medicine Associates",
    //     description: `Dara is a registered dietitian who works in private practice in Manhattan and is also the dietitian at Reproductive Medicine Associates (RMA) of New York. Dara, a Toronto, Canada native, graduated with a Bachelor of Science in Food & Nutrition at The University of Western Ontario and obtained her RD and Masters in Nutrition at New York University.`,
    //     linkedinUrl: "https://www.linkedin.com/in/dara-godfrey-b1a3421a/",
    // },

    // {
    //     name: "Ken Callahan",
    // role: "Advisor",
    //     title: "Principal at The Hargan Group",
    //     description: `Kenneth Callahan III, is an experienced government and healthcare specialist with an extensive background in public policy, reimbursement strategies, stakeholder engagement and strategic communications.`,
    //     linkedinUrl: "https://www.linkedin.com/in/kenneth-c-iii/",
    // },
    // {
    //     name: "Paul Schwendel",
    // role: "Advisor",
    //     title: "Managing Partner at Schwendel - Van Deusen, LLC",
    //     description: `With over two decades of dedicated service in the healthcare industry, Paul Schwendel is a seasoned leader renowned for his extensive experience and profound impact on healthcare management and operations. As Managing Partner at Schwendel-Van Deusen since 1999, he drives strategic growth and innovation. Previously, he held VP roles at DaVita Kidney Care and AMN Healthcare, shaping operational excellence.`,
    //     linkedinUrl: "https://www.linkedin.com/in/paul-schwendel-a67a81252/",
    // },
];
