import { useState } from "react";
import styles from "./LeaderCard.module.css";
import IconLink from "../IconLink/IconLink";

function LeaderCard({
    name,
    title,
    headshot,
    linkedinUrl,
    shortDescription,
    fullDescription,
}) {
    const [showBack, setShowBack] = useState(false);

    function handleReadMoreClick() {
        setShowBack(true);
    }

    function handleCloseClick() {
        setShowBack(false);
    }

    return (
        <div className={styles.leader_card}>
            {!showBack ? (
                <div className={styles.front} onClick={handleReadMoreClick}>
                    <div className={styles.big_headshot_container}>
                        <div className={styles.headshot_overlay}></div>
                        <img
                            src={headshot}
                            alt=""
                            className={[
                                styles.big_headshot,
                                styles.headshot,
                            ].join(" ")}
                        />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.name_details}>
                            <div className={styles.name}>{name}</div>
                            <div className={styles.title}>{title}</div>
                        </div>
                        {linkedinUrl ? (
                            <IconLink
                                style={{ width: 22, height: 22 }}
                                src={"/images/linkedin-logo-white.svg"}
                                href={linkedinUrl}
                                alt={`Link to ${name}'s LinkedIn profile`}
                            />
                        ) : null}
                    </div>
                    <div className={styles.short_description}>
                        {shortDescription}
                    </div>
                    <div className={styles.read_more}>Read more</div>
                </div>
            ) : (
                <div className={styles.back}>
                    <div className={styles.back_top}>
                        <div className={styles.small_headshot_container}>
                            <div className={styles.headshot_overlay}></div>
                            <img
                                src={headshot}
                                alt=""
                                className={[
                                    styles.headshot,
                                    styles.small_headshot,
                                ].join(" ")}
                            />
                        </div>
                        <div
                            className={styles.close_button}
                            onClick={handleCloseClick}
                        >
                            <img
                                src="/images/leadercard-close-button.svg"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className={styles.back_middle}>
                        <div className={styles.name}>{name}</div>
                        <div className={styles.title}>{title}</div>
                    </div>
                    <div className={styles.back_bottom}>{fullDescription}</div>
                </div>
            )}
        </div>
    );
}

export default LeaderCard;
