import styles from "./ToolCard.module.css";
function ToolCard({ name, iconSrc, iconAlt, description }) {
    return (
        <div className={styles.tool_card}>
            <img src={iconSrc} alt={iconAlt} />
            <strong>{name}</strong>
            <p>{description}</p>
        </div>
    );
}

export default ToolCard;
