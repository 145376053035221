import { Link } from "react-router-dom";
import styles from "./IconLink.module.css";

function IconLink({ src, href, style, alt = "", target = "_blank" }) {
    return (
        <Link
            target={target}
            rel="noopener noreferrer"
            to={href}
            className={styles.iconlink}
            aria-label={alt}
            style={style}
        >
            <img src={src} alt="" />
        </Link>
    );
}

export default IconLink;
