import { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Policy from "../../components/Policy/Policy";
import { privacyPolicy } from "../../data/legal";

function PrivacyPolicyPage() {
    useEffect(() => {
        document.title = "Privacy Policy | Keel Digital Solutions";
        window.scrollTo(0, 0);
    });
    return (
        <div>
            <Header dark={true} />
            <main>
                <Policy title="Privacy policy" data={privacyPolicy} />
            </main>
            <Footer />
        </div>
    );
}

export default PrivacyPolicyPage;
