import styles from "./SectionHeading.module.css";

function SectionHeading({ heading, children, textStyle, headingStyle }) {
    return (
        <div className={styles.section_heading}>
            <h2 className={styles.heading} style={headingStyle}>
                {heading}
            </h2>
            <p className={styles.description} style={textStyle}>
                {children}
            </p>
        </div>
    );
}

export default SectionHeading;
