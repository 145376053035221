import styles from "./AboutUsPage.module.css";
import Header from "../../components/Header/Header";
import VideoHero from "../../components/VideoHero/VideoHero";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import ColorBanner from "../../components/ColorBanner/ColorBanner";
import Footer from "../../components/Footer/Footer";
import { useEffect } from "react";

function AboutUsPage() {
    useEffect(() => {
        document.title = "About Us | Keel Digital Solutions";
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Header />
            <main id={styles.main}>
                <VideoHero id={styles.hero}>
                    <div id={styles.hero_top}>
                        <h1>We’re bridging the gaps in mental health care</h1>
                        <p>
                            We are building Keel Digital Solutions to be the
                            preeminent platform provider in the digital training
                            & delivery of unique healthcare solutions.
                        </p>
                    </div>
                    <div className={styles.hero_bottom}>
                        <div className={styles.hero_image_cards_container}>
                            <img
                                src="/images/about-us-laptop.png"
                                alt="A man wearing headphones while using a laptop"
                                className={styles.hero_image_card}
                            />
                            <img
                                src="/images/about-us-lab.jpg"
                                alt="A woman using a microscope in a laboratory"
                                className={styles.hero_image_card}
                            />
                            <img
                                src="/images/about-us-phone.png"
                                alt="A woman lying on a couch while using her phone for a video call"
                                className={styles.hero_image_card}
                            />
                        </div>
                    </div>
                </VideoHero>
                <section id={styles.platforms}>
                    <div className={styles.platforms_left}>
                        <div
                            className={[
                                styles.platform_card,
                                styles.active,
                            ].join(" ")}
                        >
                            <div className={styles.platform_card_text}>
                                <strong>Our platform</strong>
                                <p>Wants you to improve and gain solutions</p>
                            </div>
                            <img src="/images/our-platform-icon.png" alt="" />
                        </div>
                        <div className={styles.platform_card}>
                            <div className={styles.platform_card_text}>
                                <strong>Other platforms</strong>
                                <p>
                                    Encourage frequent professional
                                    consultations
                                </p>
                            </div>
                            <img
                                src="/images/other-platforms-icon.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className={styles.platforms_right}>
                        <h2>How we're different</h2>
                        <p>
                            Many mental health organizations focus on keeping
                            clients engaged on their platform and encourage
                            frequent professional consultations to drive
                            revenue. At Keel, our mission is different: we aim
                            to equip our clients with the tools to manage their
                            mental wellness on their own terms, at your their
                            pace, and when it best fits their life. Professional
                            services are always available through your
                            organization’s network and our system is designed to
                            help connect you with the right people for your
                            needs.
                        </p>
                    </div>
                </section>
                <section id={styles.what_we_value}>
                    <SectionHeading
                        heading={"What we value"}
                        headingStyle={{
                            fontFamily: "Inter",
                            fontWeight: "600",
                        }}
                        textStyle={{
                            maxWidth: 750,
                        }}
                    >
                        Mental health isn't our business - it's woven into our
                        company fabric. From product development to client
                        communication, mental health and wellbeing is key to
                        every part of Keel.
                    </SectionHeading>
                    <div className={styles.values_grid}>
                        <div className={styles.value}>
                            <div className={styles.value_number}>01</div>
                            <div className={styles.value_content}>
                                <strong>Client success</strong>
                                <p>
                                    We are dedicated to offering our clients
                                    continuous support and ensuring their
                                    success from the start through live
                                    demonstrations, training sessions, and
                                    proactive communication.
                                </p>
                            </div>
                        </div>
                        <div className={styles.value}>
                            <div className={styles.value_number}>02</div>
                            <div className={styles.value_content}>
                                <strong>Company wide</strong>
                                <p>
                                    Our commitment to mental health is embedded
                                    in our core principles. We ensure that our
                                    employees feel valued, heard, and supported.
                                </p>
                            </div>
                        </div>
                        <div className={styles.value}>
                            <div className={styles.value_number}>03</div>
                            <div className={styles.value_content}>
                                <strong>Science-backed research</strong>
                                <p>
                                    We are guided by the latest research, data,
                                    and evidence in mental health and
                                    endocrinology, ensuring that the latest
                                    scientific findings and robust evidence
                                    inform our practices.
                                </p>
                            </div>
                        </div>
                        <div className={styles.value}>
                            <div className={styles.value_number}>04</div>
                            <div className={styles.value_content}>
                                <strong>Expert collaboration</strong>
                                <p>
                                    We have a robust advisory team with
                                    expertise that spans technology, physical,
                                    and mental health disciplines across North
                                    America.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <ColorBanner />
            </main>
            <Footer />
        </div>
    );
}

export default AboutUsPage;
