import { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Policy from "../../components/Policy/Policy";
import { cookiePolicy } from "../../data/legal";

function CookiePolicyPage() {
    useEffect(() => {
        document.title = "Cookie Policy | Keel Digital Solutions";
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Header dark={true} />
            <main>
                <Policy title="Cookie policy" data={cookiePolicy} />
            </main>
            <Footer />
        </div>
    );
}

export default CookiePolicyPage;
