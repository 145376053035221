import Header from "../../components/Header/Header";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import styles from "./LeadershipPage.module.css";
import { ourAdvisors, ourLeadership } from "../../data/people";
import AdvisorCard from "../../components/AdvisorCard/AdvisorCard";
import Footer from "../../components/Footer/Footer";
import LeaderCard from "../../components/LeaderCard/LeaderCard";
import VideoHero from "../../components/VideoHero/VideoHero";
import ColorBanner from "../../components/ColorBanner/ColorBanner";
import { useEffect } from "react";

function LeadershipPage() {
    useEffect(() => {
        document.title = "Company | Keel Digital Solutions";
        window.scrollTo(0, 0);
    }, []);

    return (
        <div id={styles.leadership}>
            <Header />
            <main id={styles.main}>
                <VideoHero id={styles.our_leadership_hero}>
                    <div id={styles.hero_top}>
                        <div id={styles.hero_text}>
                            <h1>Our Leadership</h1>
                            <p>
                                Keel Digital Solutions' executive has expertise
                                across a variety of industries including
                                technology, banking, professional sports and
                                health care.
                            </p>
                        </div>
                        <div>
                            <div id={styles.hero_quote}>
                                <div className={styles.quote}>
                                    <span className={styles.quotation_mark}>
                                        “
                                    </span>
                                    Let's embrace a future where technology not
                                    only advances our capabilities but also
                                    supports our mental wellness.
                                </div>
                                <div className={styles.quote_source}>
                                    Rob Godfrey
                                    <div className={styles.quote_source_title}>
                                        Chief Executive Officer
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id={styles.hero_bottom}
                        className={styles.leaders_container}
                    >
                        {ourLeadership.map((l) => (
                            <LeaderCard key={l.name} {...l} />
                        ))}
                    </div>
                </VideoHero>
                <section id={styles.our_advisors}>
                    <SectionHeading
                        heading={"Our board & advisors"}
                        textStyle={{
                            lineHeight: "20px",
                            maxWidth: 670,
                        }}
                    >
                        Keel Digital Solutions has a robust board member and
                        advisory team with expertise that spans technology,
                        physical, and mental health disciplines across North
                        America.
                    </SectionHeading>
                    <div className={styles.advisors_container}>
                        {ourAdvisors.map((a) => (
                            <AdvisorCard key={a.name} {...a} />
                        ))}
                    </div>
                </section>
                <ColorBanner />
            </main>
            <Footer />
        </div>
    );
}

export default LeadershipPage;
