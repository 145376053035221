import { useState } from "react";
import styles from "./TabbedCarousel.module.css";

function TabbedCarousel({ data }) {
    const [currentTab, setCurrentTab] = useState(0);

    function handleTabClick(index) {
        setCurrentTab(index);
    }

    return (
        <div className={styles.tabbed_carousel}>
            <div className={styles.tabs_container}>
                {Object.keys(data).map((key, index) => (
                    <div
                        key={key}
                        className={[
                            styles.tab,
                            index === currentTab ? styles.active : undefined,
                        ].join(" ")}
                        onClick={() => handleTabClick(index)}
                    >
                        <span className={styles.tab_index}>
                            {(index + 1).toString().padStart(2, "0")}
                        </span>
                        <div className={styles.tab_label}>{key}</div>
                    </div>
                ))}
            </div>
            <div className={styles.current_content}>
                {((content) => {
                    return (
                        <>
                            <img src={content.img} alt="" />
                            <div className={styles.description}>
                                {content.description}
                            </div>
                        </>
                    );
                })(Object.values(data)[currentTab])}
            </div>
        </div>
    );
}

export default TabbedCarousel;
