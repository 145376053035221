import styles from "./ColorBanner.module.css";

function ColorBanner({
    color = "var(--keel-navy)",
    linkText = "Contact us",
    linkHref = "/contact-us",
    heading = "Let's build the future of mental health together",
    headingStyle = {},
    linkStyle = {},
    hideLink = false,
}) {
    return (
        <section
            className={styles.color_banner}
            style={{ backgroundColor: color }}
        >
            <h1 style={headingStyle}>{heading}</h1>
            {!hideLink && (
                <a
                    className={["orange_button", styles.link_button].join(" ")}
                    href={linkHref}
                    style={linkStyle}
                >
                    {linkText}
                </a>
            )}
        </section>
    );
}

export default ColorBanner;
