import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import IconLink from "../IconLink/IconLink";
import { footerLinks } from "../../data/navigation";

function Footer() {
    return (
        <footer id={styles.footer}>
            <div id={styles.footer_wrapper}>
                <div id={styles.top}>
                    <div id={styles.top_left}>
                        <img
                            src={"/images/keel-logo-blue.svg"}
                            alt="Keel logo"
                            id={styles.keel_logo}
                        />
                    </div>
                    <div id={styles.top_right}>
                        {footerLinks.map((l) => (
                            <div key={l.name} className={styles.link_list}>
                                {/* <Link> */}
                                <strong>{l.name}</strong>
                                {/* </Link> */}
                                {l.children.map((c) => (
                                    <Link
                                        key={c.name}
                                        to={c.href}
                                        target={c.target}
                                        rel="noopener noreferrer"
                                    >
                                        {c.name}
                                    </Link>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>

                <div id={styles.bottom}>
                    <div id={styles.bottom_left}>
                        <span>
                            © Copyright 2024 | All Rights Reserved |
                            Keel.digital
                        </span>
                    </div>
                    {/* <div id={styles.bottom_middle}>
                        <Link to="/">Privacy Policy</Link>
                        <Link to="/">Cookie Policy</Link>
                        <Link to="/">Terms & Conditions</Link>
                    </div> */}
                    <div id={styles.bottom_right}>
                        {/* <IconLink
                            src={"/images/facebook-logo.svg"}
                            href={"/"}
                        /> */}
                        <IconLink
                            src={"/images/linkedin-logo.svg"}
                            href={
                                "https://www.linkedin.com/company/keeldigitalsolutions/"
                            }
                        />
                        <IconLink
                            src={"/images/instagram-logo.svg"}
                            href={
                                "https://www.instagram.com/keeldigitalsolutions/"
                            }
                        />
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
