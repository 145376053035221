import React from "react";
import ReactDOM from "react-dom/client";
import "./globals.css";
import "./fonts.css";
import HomePage from "./pages/Home/HomePage";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LeadershipPage from "./pages/Leadership/LeadershipPage";
import AboutUsPage from "./pages/AboutUs/AboutUsPage";
import ContactUsPage from "./pages/ContactUs/ContactUsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicy/PrivacyPolicyPage";
import CookiePolicyPage from "./pages/CookiePolicy/CookiePolicyPage";

const root = ReactDOM.createRoot(document.getElementById("root"));

function Root() {
    return (
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<HomePage />} />
                    <Route path="our-team" element={<LeadershipPage />} />
                    <Route path="about-us" element={<AboutUsPage />} />
                    <Route path="contact-us" element={<ContactUsPage />} />
                    <Route
                        path="privacy-policy"
                        element={<PrivacyPolicyPage />}
                    />
                    <Route
                        path="cookie-policy"
                        element={<CookiePolicyPage />}
                    />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );
}

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default HomePage;
