import styles from "./AdvisorCard.module.css";
import IconLink from "../IconLink/IconLink";
// import { useState } from "react";

function AdvisorCard({ name, role, title, description, linkedinUrl }) {
    // const [expanded, setExpanded] = useState(false);

    // function handleExpandClick() {
    //     setExpanded((prev) => !prev);
    // }

    return (
        <div className={styles.advisor_card}>
            <div className={styles.top}>
                <div className={styles.role}>{role}</div>
                {linkedinUrl && (
                    <IconLink
                        style={{
                            width: 16,
                            height: 16,
                            opacity: 0.5,
                        }}
                        src={"/images/linkedin-logo.svg"}
                        href={linkedinUrl}
                        alt={`Link to ${name}'s LinkedIn profile`}
                    />
                )}
            </div>
            <div className={styles.bottom}>
                <div className={styles.name}>{name}</div>
                <div className={styles.title}>{title}</div>
            </div>
        </div>
    );
}

export default AdvisorCard;
