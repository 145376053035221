import Header from "../../components/Header/Header";
import styles from "./HomePage.module.css";
import Footer from "../../components/Footer/Footer";
import SectionHeading from "../../components/SectionHeading/SectionHeading";
import SolutionComponent from "../../components/SolutionComponent/SolutionComponent";
import TabbedCarousel from "../../components/TabbedCarousel/TabbedCarousel";
import ToolCard from "../../components/ToolCard/ToolCard";
import VideoHero from "../../components/VideoHero/VideoHero";
import { useEffect } from "react";
// import keelLabIconSmall from "../assets";
// import whiteArrowRight from "../assets/images/white-arrow-right.svg";

function HomePage() {
    useEffect(() => {
        document.title = "Home | Keel Digital Solutions";
        window.scrollTo(0, 0);
    }, []);

    return (
        <div id={styles.home}>
            <Header />
            <main>
                <VideoHero id={styles.hero}>
                    <div id={styles.hero_card}>
                        <div id={styles.hero_card_top}>
                            <h1>Practice. Analytics. Life.</h1>
                        </div>
                        <div id={styles.hero_subtitle}>
                            <div>
                                We believe in building a future where mental
                                health care
                            </div>
                            <div>goes beyond... "how do you feel?"</div>
                        </div>
                    </div>
                </VideoHero>
                <div id={styles.main}>
                    <section id={styles.our_solutions}>
                        <SectionHeading heading={"Our solutions"}>
                            Our two business lines have been built around the
                            idea that every person will experience their unique
                            mental health journey. We are here to support our
                            clients every step of the way, combining their
                            thoughts, feelings, and biomarkers with our
                            analytics to deliver a complete report on their
                            mental health.
                        </SectionHeading>
                        <div className={styles.solutions_container}>
                            <SolutionComponent
                                heading={"Keel Mind"}
                                iconSrc={"/images/keel-mind-icon-small.svg"}
                                bigGraphicSrc={
                                    "/images/keel-mind-graphic-big.png"
                                }
                                smallGraphicSrc={
                                    "/images/keel-mind-graphic-small.png"
                                }
                                buttonHref={"https://keelmind.com"}
                            >
                                We provide organizations with an AI-driven
                                platform that facilitates behavioral health
                                training and education while delivering
                                measurement-based care.
                            </SolutionComponent>
                            <SolutionComponent
                                reversed={true}
                                heading={"Keel Labs"}
                                iconSrc={"/images/keel-lab-icon-small.svg"}
                                bigGraphicSrc={
                                    "/images/keel-lab-graphic-big.png"
                                }
                                smallGraphicSrc={
                                    "/images/keel-lab-graphic-small.png"
                                }
                                buttonHref={"/contact-us"}
                            >
                                We proudly partner with some of North America's
                                largest labs to process and report endocrinology
                                results. We provide self-administered saliva
                                tests for various biomarkers such as cortisol,
                                insulin resistance, and sex hormones.
                            </SolutionComponent>
                        </div>
                    </section>
                    <div id={styles.home_plexus}></div>
                    <section id={styles.our_approach}>
                        <SectionHeading heading={"Our approach"}>
                            At Keel Digital, rather than targeting individual
                            consumers, we partner directly with government
                            agencies and businesses to offer their organizations
                            access to our Keel Mind mental health platform.
                            Similarly, our Keel Labs saliva tests are offered
                            exclusively through partnerships with third-party
                            organizations and are not offered directly to
                            consumer. Across both lines of business our mission
                            is to deliver the most technologically advanced
                            mental health care available.
                            <br />
                            <br />
                            Within our business, we strategically target three
                            key verticals:
                        </SectionHeading>
                        <TabbedCarousel
                            data={{
                                "First responders": {
                                    img: "/images/first-responders-graphic.png",
                                    description: `Keel Mind supports first responders facing trauma from their urgent, unpredictable, and often life-threatening work.

Through AI-powered interviews and unit-specific questionnaires, our platform facilitates connections with certified peer supporters within your organization. This approach fosters stronger, more personalized connections between members and their peer supporters.`,
                                },
                                Indigenous: {
                                    img: "/images/indigenous-graphic.png",
                                    description: `Indigenous communities are often remote and have little access to adequate mental health supports.

To address this, governments at various levels in both Canada and the United States support our mission in indigenous communities, enabling us to work directly with community members to provide culturally informed mental health tools that respect and honour their history and traditions.`,
                                },
                                Academia: {
                                    img: "/images/academia-graphic.png",
                                    description: `Keel Mind partners with leading post-secondary institutions across Canada and the United States to provide students and supervisors with AI-powered tools to foster the next generation of mental health clinicians. We have had proven success in training students at over 35 institutions and 52 programs.

We will continue to expand its geographic footprint at academic institutions in both Canada and the United States.
`,
                                },
                            }}
                        />
                        <div className={styles.government_logos_container}>
                            <img
                                src="/images/ontario-logo.png"
                                alt="ontario logo"
                            />
                            <img
                                src="/images/government-of-canada-logo.png"
                                alt="government of canada logo"
                            />
                        </div>
                    </section>
                    <section>
                        <div id={styles.building_trust_intro}>
                            We're building trust and assurity within these
                            organizations using the following tools
                        </div>
                        <div id={styles.tool_cards_container}>
                            <ToolCard
                                iconSrc={
                                    "/images/self-assessment-tool-icon.png"
                                }
                                name={"Self assessment"}
                                description={
                                    "A tool that allows our clients to gain quick insights into their mental readiness and decide when it's the right time to seek help."
                                }
                            />
                            <ToolCard
                                iconSrc={"/images/keel-reels-tool-icon.png"}
                                name={"Keel Reels"}
                                description={
                                    "An AI-driven chatbot interview that improves rapport between care providers and clients based on story-telling and lived experience."
                                }
                            />

                            <ToolCard
                                iconSrc={"/images/live-sessions-tool-icon.png"}
                                name={
                                    "AI & Analytics, Virtual Interactions, Training & Analytics"
                                }
                                description={
                                    "Private and virtual video sessions, AI decision support tools, and analytics provides an expansive toolbox to increase care provider productivity."
                                }
                            />
                        </div>
                    </section>
                    <section id={styles.our_evidence}>
                        <div
                            className="big_dark_card"
                            id={styles.evidence_card}
                        >
                            <SectionHeading
                                heading={"Our evidence"}
                                headingStyle={{
                                    color: "white",
                                }}
                                textStyle={{ color: "white" }}
                            >
                                We are dedicated to continuously testing and
                                refining our solutions to ensure they
                                consistently deliver measureable and impactful
                                results. Through ongoing evaluation and
                                iteration, we rigorously assess performance,
                                make data-driven adjustments, and enhance our
                                offerings to meet and exceed our clients'
                                expectations
                            </SectionHeading>
                            <div className={styles.evidence_container}>
                                <div className={styles.evidence}>
                                    <h1>150k+</h1>
                                    <div>
                                        Sessions provided to
                                        <br />
                                        individuals in need of care
                                    </div>
                                </div>
                                <div className={styles.evidence}>
                                    <h1>20k+</h1>
                                    <div>
                                        Saliva tests taken
                                        <br />
                                        with quick results
                                    </div>
                                </div>
                                <div className={styles.evidence}>
                                    <h1>85k+</h1>
                                    <div>
                                        Clients helped using <br />
                                        our solutions
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default HomePage;
