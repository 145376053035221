export const navLinks = [
    { name: "About us", href: "/about-us" },
    { name: "Our team", href: "/our-team" },
    { name: "Contact us", href: "/contact-us", hamburgerOnly: true },
];

export const footerLinks = [
    {
        name: "Company",
        children: [
            { name: "About us", href: "/about-us" },
            { name: "Our team", href: "/our-team" },
            {
                name: "Keelmind.com",
                href: "https://keelmind.com",
                target: "_blank",
            },
        ],
    },
    {
        name: "Legal",
        children: [
            { name: "Privacy Policy", href: "/privacy-policy" },
            { name: "Cookie Policy", href: "/cookie-policy" },
        ],
    },
    {
        name: "Contact us",
        children: [
            { name: "admin@keel.digital", href: "mailto:admin@keel.digital" },
        ],
    },
];
